const slugifyLib = require('slugify');

const slugify = text => slugifyLib(text, { lower: true, strict: true });

const mapPosts = nodes => nodes.map(({ post }) => ({
  title: post.frontmatter.title,
  author: post.frontmatter.author,
  image: post.frontmatter.image,
  description: post.excerpt,
  link: `/blog/${slugify(post.frontmatter.title)}`,
}));

module.exports = {
  slugify,
  mapPosts,
};
