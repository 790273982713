import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { mapPosts } from '../misc/dataParsers';
import Layout from '../layout';
import HomeBlog from '../components/HomeBlog';
import './blog.scss';

const BlogPage = props => {
  const {
    data: { blog },
    location,
  } = props;

  const [subscriptionEmail, setSubscriptionEmail] = useState('');
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  const handleSubscription = e => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      body: JSON.stringify({ email: subscriptionEmail }),
    })
      .then(() => {
        setSubscriptionEmail('');
        setSubscriptionSuccess(true);
        setTimeout(() => setSubscriptionSuccess(false), 2000);
      });
  };

  return (
    <Layout pathname={location.pathname} title="Blog" className="blog-page">
      <div className="blog-page-top-banner-container">
        <div className="cover">
          <div className="subscription-title">
            Discover the stories of MLReef and its people
          </div>
          <p className="subscription-text-line">
            Suscribe to our newsletter to not miss any article!
          </p>
          <form
            onSubmit={handleSubscription}
            method="post"
            className="subscription-form d-flex"
            target="_blank"
            noValidate
          >
            <div className="subscription-form-group btn-group mx-auto t-center">
              <input
                type="email"
                className="subscription-form-control border-rounded-left"
                placeholder="Enter your email"
                value={subscriptionEmail}
                onChange={e => setSubscriptionEmail(e.target.value)}
                required
              />
              <button type="submit" className="btn btn-primary px-3">
                Sign up
              </button>
            </div>

          </form>
          <div className={`subscription-success ${subscriptionSuccess ? 'show' : ''}`}>
            thanks!
          </div>
        </div>
      </div>
      <div className="blog-page-content">
        <HomeBlog noTitle posts={mapPosts(blog.nodes)} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    blog: allFile(
      filter: {sourceInstanceName: {eq: "blog"}},
      sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}
    ) {
      nodes {
        post: childMarkdownRemark {
          frontmatter {
            author
            image
            title
          }
          excerpt
        }
      }
    }
  }
`;

export default BlogPage;
