import React from 'react';
import MLink from '../MLink';
import './HomeBlog.scss';

const HomeBlog = props => {
  const { posts, noTitle, limit } = props;

  return (
    <section className="home-blog">
      {!noTitle && (
        <div className="home-blog-title">
          <h2 className="title">The latest from our blog</h2>
        </div>
      )}
      <div className="home-blog-content">
        {posts.slice(0, limit).map(post => (
          <div key={post.title} className="home-blog-post card">
            <MLink className="neutral" to={post.link}>
              <div className="home-blog-post-container card-container large">
                {post.image && (

                  <div
                    className="home-blog-post-image"
                    style={{ backgroundImage: `url(${post.image})` }}
                  />
                )}
                <div className="home-blog-post-title card-title">
                  {post.title}
                </div>
                <div className="home-blog-post-subtitle">
                  {`by ${post.author}`}
                </div>
                <div className="home-blog-post-text card-text">
                  {post.description}
                </div>
              </div>
            </MLink>
          </div>
        ))}
      </div>
      <div className="home-blog-actions">
        <MLink className="m-auto" to="/blog">
          See all
        </MLink>
      </div>
    </section>
  );
};

HomeBlog.defaultProps = {
  limit: Infinity,
};

export default HomeBlog;
